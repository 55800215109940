h3 {
  padding: 0.75rem;
  position: relative;
}
h3:before {
  content: "";
  border-left: 0.25rem solid #6676ff;
  width: 0.25rem;
  position: absolute;
  height: 1rem;
  left: 0;
  top: 0.95rem;
}
.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
}
.demo-ruleForm .el-form-item {
  width: 33%;
}
.ruleFormOther {
  display: flex;
  flex-wrap: wrap;
}
.ruleFormOther .el-form-item {
  width: 50%;
}
.ruleFormOther .el-form-item /deep/ .el-textarea__inner {
  min-height: 7.5rem !important;
  resize: none;
}
